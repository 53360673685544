import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Button } from "@material-ui/core"

import { 
  Container, 
  Content,
  Background,
  BackgroundColor,
  NormalBackground,
  BackgroundOverlay
} from "./styles"

interface CtaSectionProps {
  title: string
  bgImage?: any
  bgColor?: any
  button: {
    color: "primary"
    text: string
    link: string
  }
}

const CtaSection: React.FC<CtaSectionProps> = ({ title, bgImage, bgColor, button, ...rest }) => {
  const ElementBackground = bgImage
    ? ({ children, ...rest }) => {
      return typeof bgImage === 'string' 
        ? <NormalBackground image={bgImage} { ...rest }>{children}</NormalBackground> 
        : <Background fluid={bgImage.childImageSharp.fluid} {...rest }>{children}</Background>
    }
    : ({ children }) => (
        <BackgroundColor bgColor={bgColor}>{children}</BackgroundColor>
      )
  return (
    <ElementBackground>
      <BackgroundOverlay />
      <Container {...rest}>
        <Content>
          <h2>{title}</h2>
          <AnchorLink to={button.link}>
            <Button color={button.color} variant="contained">{button.text}</Button>
          </AnchorLink>
        </Content>
      </Container>
    </ElementBackground>
  )
}

export default CtaSection
