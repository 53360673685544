import React from "react"
import { graphql } from "gatsby"

import Layout from "./main"
import SEO from "../components/seo"
import Hero from "../components/Organisms/Hero"
import FeatureSideImageSection from "../components/Organisms/FeatureSideImageSection"
import CardsSection from "../components/Organisms/CardsSection"
import FeaturedSectionWithImages from "../components/Organisms/FeaturedSectionWithImages"
import CtaSection from "../components/Organisms/CtaSection"
import LogoCompaniesSection from "../components/Organisms/LogoCompaniesSection"
import ContactSection from "../components/Organisms/ContactSection"

import { CardProps } from "../components/Molecules/Card"

interface ServicosLayoutProps {
  name: string;
  hero_title: string;
  hero_description: string;
  hero_buttonText: string;
  hero_buttonLink?: string;
  fluid_hero_background: string;
  features: IFeatures[];
  featured_section_title: string;
  featured_section_images: CardProps[];
  includes_title: string;
  includes_cards: CardProps[];
  cta_title: string
  cta_image: string
  cta_button_text: string
  cta_button_link: string
  contactFormImage?: any;
}

interface IFeatures {
  description: string;
  title: string;
  image: { childImageSharp: { fluid: any } }
}

export const ServicosLayout = ({
  name,
  hero_title,
  hero_description,
  hero_buttonText,
  hero_buttonLink,
  fluid_hero_background,
  features,
  includes_title,
  includes_cards,
  featured_section_title,
  featured_section_images,
  cta_title,
  cta_image,
  cta_button_text,
  cta_button_link,
  contactFormImage
}: ServicosLayoutProps) => (
  <>
    <SEO title={name} description={hero_description} />
    <Hero
      title={hero_title}
      description={hero_description}
      button={{
        text: hero_buttonText,
        color: 'primary',
        link: hero_buttonLink
      }}
      fluidImage={fluid_hero_background}
    />

    {features.map((feature, index) => (
      <FeatureSideImageSection
        id={index?undefined:"features"}
        description={feature.description}
        title={feature.title}
        image={feature.image.childImageSharp.fluid}
        key={index}
        reverse={index%2 === 1}
      />
    ))}

    {featured_section_images ? <FeaturedSectionWithImages
      title={featured_section_title}
      images={featured_section_images}
    /> : <hr style={{opacity: 0.3}}/>}

    <CardsSection
      title={includes_title}
      cards={includes_cards}
      alternative
    />

    <CtaSection
      title={cta_title}
      bgImage={cta_image}
      button={{
        text: cta_button_text,
        link: cta_button_link,
        color: "primary"
      }}
    />

    <LogoCompaniesSection />

    {contactFormImage && <ContactSection
        id="contato"
        fluidImage={contactFormImage}
        phoneNumberLink="tel:027996433420"
        whatsappNumberLink="https://api.whatsapp.com/send?phone=+5527996433420&text=Ol%C3%A1.%20Eu%20tenho%20interesse%20nos%20Servi%C3%A7os%20da%20Ag%C3%AAncia."
      />}
  </>
)

const Servicos = ({ data }) => {
  const {
    name,
    hero_title,
    hero_description,
    hero_buttonText,
    hero_buttonLink,
    hero_background: { childImageSharp: { fluid: fluid_hero_background } },
    features,
    featured_section_title,
    featured_section_images,
    includes_title,
    includes_cards,
    cta_title,
    cta_image,
    cta_button_text,
    cta_button_link,
  } = data.markdownRemark.frontmatter

  const contactFormImage = data.contactFormImage.childImageSharp.fluid;

  return (
    <Layout>
      <ServicosLayout
        name={name}
        hero_title={hero_title}
        features={features}
        fluid_hero_background={fluid_hero_background}
        hero_buttonText={hero_buttonText}
        hero_buttonLink={hero_buttonLink}
        hero_description={hero_description}
        includes_title={includes_title}
        includes_cards={includes_cards}
        featured_section_title={featured_section_title}
        featured_section_images={featured_section_images}
        cta_title={cta_title}
        cta_image={cta_image}
        cta_button_text={cta_button_text}
        cta_button_link={cta_button_link}
        contactFormImage={contactFormImage}
      />
    </Layout>

  )
}

export const query = graphql`
  query Servicos($slug: String!) {
    contactFormImage: file(
      relativePath: { eq: "banners/contact-banner.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 1350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        name
        hero_title
        hero_description
        hero_buttonText
        hero_buttonLink
        hero_background {
          childImageSharp {
            fluid(base64Width: 800, quality: 50) {
              base64
            }
          }
        }
        features {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        featured_section_title
        featured_section_images {
          childImageSharp {
            fixed(width: 200, height: 200) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        includes_title
        includes_cards {
          title
          icon
        }
        cta_title
        cta_image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cta_button_text
        cta_button_link
      }
    }
  }
`

export default Servicos
