import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

import myTheme from "../../../theme"

export const Container = styled.div`
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 1200px;
  padding: 0 30px;
  margin: 60px auto;

  h2 {
    color: white;
    font-size: 2em;
    text-align: center;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
  }

  .gatsby-image-wrapper {
    width: 200px;
    height: 200px;

    img {
      object-fit: contain !important;
    }
  }

  @media (max-width: ${myTheme.responsive.container}) {
    padding: 0;
    h2 {
      font-size: 1.8em;
    }
    div {
      flex-direction: column;
      margin: 0 auto;
    }
  }
`
export const Background = styled(BackgroundImage)`
  min-height: 200px;
  display: flex;
  padding: 0 30px;

  justify-content: center;
`

interface NormalBackgroundProps {
  image: string;
}

export const NormalBackground = styled.div<NormalBackgroundProps>`
  height: 480px;
  display: flex;
  padding: 0 30px;
  background-image: url(${(props) => props.image});
  background-position: top center;

  justify-content: center;
  
  @media(max-width: ${myTheme.responsive.container}){
    height: 100vh;
    max-height: 700px;
  }
`

interface IBackgroundColorProps {
  bgColor: string
}

export const BackgroundColor = styled.div<IBackgroundColorProps>`
  min-height: 480px;
  display: flex;
  padding: 0 30px;
  background-color: ${props => props.bgColor};

  justify-content: center;
`

export const BackgroundOverlay = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;

  background-color: rgba(0, 0, 0, 0.7);
  background-repeat: repeat;
  background-size: auto;
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
`