import React from "react"
import Image from "gatsby-image"

import { Container, Content } from "./styles"

interface FeaturedSectionWithImagesProps {
  title: string
  images: any[]
}

const FeaturedSectionWithImages: React.FC<FeaturedSectionWithImagesProps> = ({ title, images, ...rest }) => {
  return (
    <Container {...rest}>
      <Content>
        <h2>{title}</h2>
        <div>
          {images && images.map((image, index) => {
            const ComponentImage = typeof image === "string" 
              ? ({ ...rest }) => <img src={image} className="gatsby-image-wrapper" { ...rest }/> 
              : ({ ...rest }) => <Image fixed={image.childImageSharp.fixed} { ...rest } />
            return (
              <ComponentImage key={index} />
            )
          })}
        </div>
      </Content>
    </Container>
  )
}

export default FeaturedSectionWithImages
